<template>
  <div class="ratings-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle :category="category" />

    <v-row
      class="mt-5"
      v-if="productsToBeVoted && productsToBeVoted.length > 0"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-for="product in productsToBeVoted"
        :key="product.productId"
        class="product-col"
      >
        <ProductRatingCard
          :key="product.productId"
          v-bind:product="product.product"
          @refreshPage="refreshPage"
        />
      </v-col>

      <!-- <v-btn
        block
        v-intersect="onIntersect"
        :loading="!loaded"
        @click="loadMore"
        v-if="$vuetify.breakpoint.xs && hasMoreItems"
      >
        <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
        <template v-else>{{ $t("products.showAll") }}</template>
      </v-btn> -->
    </v-row>
    <div v-else-if="loaded">
      Nessun prodotto da votare
    </div>
    <!-- <v-pagination
      v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
      :value="page"
      :page="pager.selPage"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination> -->

    <category-block
      :target="category"
      :container="false"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductRatingCard from "@/components/product/ProductRatingCard.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixin from "~/mixins/category";

import ratingService from "~/service/ratingService";

export default {
  name: "Ratings",
  components: {
    CategoryTitle,
    ProductRatingCard
    // CategoryBlock
  },
  data() {
    return {
      productsToBeVoted: [],
      loaded: false,
      // pager: {},
      page: 1
    };
  },
  mixins: [categoryMixin],
  computed: {
    // hasMoreItems() {
    //   if (this.pager) {
    //     return this.pager.totPages > this.pager.selPage;
    //   } else {
    //     return false;
    //   }
    // },
    // totItems() {
    //   return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    // }
  },
  methods: {
    refreshPage() {
      this.fetchProductsToBeVoted(false, this.page);
    },
    async fetchProductsToBeVoted(isAddMore = false, newPage) {
      let _this = this;
      try {
        let res = await ratingService.listUserVotes("to-vote", newPage);
        if (res && res.notes) {
          if (isAddMore) {
            let prodList = _this.productsToBeVoted.concat(res.notes);
            _this.productsToBeVoted = prodList;
          } else {
            _this.productsToBeVoted = res.notes;
          }
          // this.pager = res.page;
        } else {
          this.productsToBeVoted = [];
        }
      } catch (err) {
        console.log(err);
      } finally {
        _this.loaded = true;
      }
    }
    // handlePageFilter(newPage) {
    //   var _this = this;
    //   this.page = newPage;
    //   this.fetchProductsToBeVoted(false, this.page);
    //   setTimeout(function() {
    //     _this.$vuetify.goTo(0);
    //   }, 300);
    // },
    // onIntersect(entries) {
    //   if (entries[0].isIntersecting) {
    //     this.loadMore();
    //   }
    // },
    // loadMore() {
    //   if (this.hasMoreItems) {
    //     this.page++;
    //     this.fetchProductsToBeVoted(true, this.page);
    //   }
    // }
  },
  mounted() {
    this.fetchProductsToBeVoted(false, 1);
  }
};
</script>
