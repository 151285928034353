<template>
  <v-card
    outlined
    rounded="md"
    class="product-rating-card h-100"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productRatingCard"
  >
    <div class="d-flex justify-start flex-no-wrap pa-3">
      <v-avatar
        class="left-col"
        :size="$vuetify.breakpoint.smAndDown ? '100' : '170'"
        tile
      >
        <img
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />
      </v-avatar>
      <div
        class="right-col description d-flex flex-column justify-space-between"
      >
        <div>
          <v-card-title class="name" v-text="product.name"></v-card-title>
          <v-card-subtitle class="short_descr py-1">
            <template v-if="product.shortDescr">
              {{ product.shortDescr }}<br />
            </template>
            <!-- {{ product.description }} -->
          </v-card-subtitle>
        </div>
        <div>
          <div
            v-if="
              (product.ratingSummary && product.ratingSummary.total) ||
                justVoted
            "
            class="avg d-flex justify-end align-center pr-2"
          >
            <template v-if="justVoted">
              <v-rating
                empty-icon="$ratingFull"
                full-icon="$ratingFull"
                half-icon="$ratingHalf"
                hover
                color="promo"
                dense
                half-increments
                length="5"
                readonly
                :value="ratingUpdated.value.avg"
                class="d-flex"
              >
              </v-rating>
              <span
                class="avg-total-count ml-2 ml-md-1 text-caption grey--text"
              >
                {{ ratingUpdated.value.avg | number(1) }}|{{
                  ratingUpdated.value.total
                }}
                {{ $t("product.rating.productRating.review") }}
              </span>
            </template>
            <template v-else>
              Altri {{ product.ratingSummary.total }} clienti hanno votato il
              prodotto
            </template>
          </div>
          <v-card-actions class="justify-end">
            <v-btn
              color="primary"
              min-width="125"
              depressed
              :disabled="justVoted"
              @click.prevent.stop=""
              @click="openVoteModal()"
            >
              {{ $t("product.rating.productRating.voteBtn") }}
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-rating-card {
  .right-col {
    width: -webkit-fill-available;
    .name {
      font-size: 16px;
      line-height: 1.4em;
    }
  }
  .v-rating button {
    font-size: 16px !important;
  }
}
</style>
<script>
import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";
import RatingService from "~/service/ratingService";

export default {
  name: "ProductRatingCard",
  props: {
    product: { type: Object, required: true }
  },
  data() {
    return {
      justVoted: false,
      ratingUpdated: null
    };
  },
  methods: {
    async openVoteModal() {
      let res = await this.$dialog.show(ProductCustomerVotes, {
        waitForResult: true,
        contentClass: "customer-votes-modal pa-5",
        productId: this.product.productId
      });
      if (res) {
        this.ratingUpdated = await RatingService.getRating(
          this.product.productId
        );
        this.justVoted = true;
        this.$emit("refreshPage");
      }
    }
  }
};
</script>
